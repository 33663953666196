/**
 * About component
 *
 * Space for you to describe more about yourself.
 */

import React from "react";

/**
 * About background image
 *
 * Below is a sample image. Upload the image of your choice into the "images"
 * directory and import here for use. Then, set imageAltText to string that
 * represents what you see in that image.
 *
 * Need an image? Check out https://unsplash.com to download a image you
 * freely use on your site.
 */
import image from "../images/mosaic.svg";

const imageAltText = "purple and blue abstract background";

/**
 * Sort description that expands on your title on the Home component.
 */
const description =
  "I have a passion for creating easy to use solutions solving real business challenges. I deliver solutions that are easy to maintain and can be managed by the business.";

/**
 * List of some of skills or technologies you work on, are learning,
 * passionate about, or enjoy,
 */
const skillsList = [
  "SharePoint",
  "OneDrive",
  "Teams",
  "Viva",
  "Powershell",
  "Power Apps",
  "Power Automate",
  "Power BI",
];

const solutionsList = [
  "IT Service Management",
  "Microsoft 365 Administration",
  "Governance",
  "Information protection",
  "Power Users and Citizen Developers",
  "Organizational change management",
  "End-user adoption",
  "Document management",
  "Business process automation",
  "Application lifecycle management",
];
/**
 * Use this to give more information about what you are passionate about,
 * how you best work, or even a quote. This will help someone learn more
 * about you on a professional level.
 */
const detailOrQuote =
  "I am a freelance IT consultant who started with SharePoint in 2003. Since then I have built broad experience working in various industries in different roles.";

const finalQuote =
  "I work with people first, then technology. Applying M365 to my customers context of work and creating value to the business from modern work methods.";

const About = () => {
  return (
    <section className="light" id="about">
      <img className="background" src={image} alt={imageAltText} />
      <div
        style={{
          backgroundColor: "white",
          width: "50%",
          padding: "4rem",
          margin: "3rem auto",
          textAlign: "center",
        }}
      >
        <h2>About me</h2>
        <p className="large">{description}</p>
        <hr />
        <ul
          style={{
            textAlign: "left",
            columns: 2,
            fontSize: "1.25rem",
            margin: "2rem 3rem",
            gap: "3rem",
          }}
        >
          {skillsList.map((skill) => (
            <li key={skill}>{skill}</li>
          ))}
        </ul>
        <hr />
        <p className="large">{detailOrQuote}</p>
        <hr />
        <ul
          style={{
            textAlign: "left",
            columns: 2,
            fontSize: "1.25rem",
            margin: "2rem 3rem",
            gap: "3rem",
          }}
        >
          {solutionsList.map((skill) => (
            <li key={skill}>{skill}</li>
          ))}
        </ul>
        <hr />
        <p className="large">{finalQuote}</p>
      </div>
    </section>
  );
};

export default About;

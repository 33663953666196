import React from "react";

/**
 * Embed a pdf file with download option for viewer
 */

const Resume = () => {
  return (
    <section className="light" id="resume">
      <h2>Resume</h2>
      <div className="resume">
        <iframe
          src="https://csbf43f4d4183bbx455ex901.blob.core.windows.net/public/Consultancy%20CV%20Eric%20Mases.pdf"
          width="100%"
          height="1000px"
          title="Eric Mases CV"
        ></iframe>
      </div>
    </section>
  );
};

export default Resume;
